<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header @clickRight="clickRight" callBack @click="goBack">
      <template>交子换购专区</template>
      <template v-slot:right v-if="token">兑换记录</template>
    </Header>
    <div class="anyTypelist_box">
      <van-tabs v-model="activeTab">
        <van-tab v-for="list in anyTypelist" :title="list.name" :key="list.id">
        </van-tab>
      </van-tabs>
    </div>
    <div style="padding: 10px 20px">
      <!-- 搜索 -->
      <div class="jiaozi">
        我的可用交子：<span>{{ token ? pointOwn || 0 : "--" }}</span>
      </div>
    </div>
    <div class="container" ref="container">
      <div class="def" v-if="!list.length">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>暂无数据</p>
      </div>
      <div style="padding: 0 20px">
        <!-- list -->
        <div class="list">
          <div
            class="bg"
            v-for="item in list"
            :key="item.id"
            @click="goTo(item)"
          >
            <div class="item">
              <div class="img">
                <img :src="item.goods.listPic" alt="" />
                <div
                  class="over"
                  v-if="new Date(item.endTime).getTime() <= Date.now()"
                >
                  <img src="../../../static/image/over.png" alt="" />
                </div>
              </div>
              <div class="title">{{ item.goods.name }}</div>
              <div class="total">{{ item.pointNumber }}交子可兑换</div>
              <div class="pic">
                <div class="left"><span>¥</span>{{ item.currentPrice }}</div>
                <div class="right">¥{{ item.originalPrice }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addBridgeForAndroidWebView } from "sdbridge-android";
export default {
  name: "jiaoziChange",
  data() {
    return {
      query: {
        pageindex: 1,
        pagesize: 20,
        name: "",
        typeId: 0,
      },
      searchVal: "",
      list: [],
      noMore: false,
      initLoading: true,
      token: null,
      pointOwn: 0,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      bridge: null,
      anyTypelist: [],
      activeTab: 0,
    };
  },
  watch: {
    activeTab(n) {
      const res = this.anyTypelist[n];
      this.query.typeId = res.id;
      this.getGoodsComposeList();
    },
  },
  mounted() {
    this.initLoading = true;
    // this.token = localStorage.getItem('token');
    this.token = this.$route.query.token;
    if (this.token) {
      this.query.token = this.token;
    }
    this.getGoodsComposeList();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
    this.$api.getComposerTypeList().then((e) => {
      const res = [
        {
          id: 0,
          name: "全部",
        },
        ...(e.data || []),
      ];
      this.anyTypelist = res;
    });
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.moreCb);
    }
  },
  methods: {
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else {
          this.bridge.callHandler(
            "jumptoapp",
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      } else {
        this.$router.back();
      }
    },
    goTo(item) {
      if (this.token) {
        this.$store.commit("REMOVE_NEEDGOODS");
        if (this.$route.query.platform) {
          this.$router.push(
            `/jiaoziChangeCenter?id=${item.id}&token=${this.token}&platform=${this.$route.query.platform}`
          );
        } else {
          this.$router.push(
            `/jiaoziChangeCenter?id=${item.id}&token=${this.token}`
          );
        }
      } else {
        if (this.$route.query.platform) {
          this.$router.push(
            `/jiaoziChangeCenter?id=${item.id}&platform=${this.$route.query.platform}`
          );
        } else {
          this.$router.push(`/jiaoziChangeCenter?id=${item.id}`);
        }
      }
    },
    moreCb(e) {
      const target = e.target;
      if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
        console.log("到底了");
        if (!this.noMore) {
          this.query.pageindex++;
          this.getGoodsComposeList("more");
        }
      }
    },
    search() {
      this.query.name = this.searchVal;
      this.query.pageindex = 1;
      this.getGoodsComposeList();
    },
    clickRight() {
      if (this.token) {
        this.$router.push("/jiaoziChangeRecord?token=" + this.token);
      }
    },
    async getGoodsComposeList(isMore) {
      try {
        const data = await this.$api.getPointComposeList(this.query);
        if (data.code === 0) {
          if (isMore) {
            this.list = this.list.concat(data.data || []);
          } else {
            this.list = data.data || [];
          }
          this.pointOwn = data.point_own;
          if ((data.data || []).length < this.query.pagesize) {
            this.noMore = true;
          }
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
        return this.$toast(error.message);
      } finally {
        this.initLoading = false;
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener("scroll", this.moreCb, true);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-tabs__line {
  display: none;
  // font-size: 20px;
}

/deep/.van-tabs {
  width: 100%;
}
/deep/.van-tab__text {
  font-weight: 600;
}
/deep/.van-tab--active {
  font-size: 20px;
  font-weight: 900;
}
.jiaozi {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  span {
    color: #0754d3;
  }
}
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 120px;
  overflow: auto;
}
.search {
  display: flex;
  background: #f8f8f8;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .left {
    display: flex;
    align-items: center;
    flex: 1;
    input {
      font-size: 14px;
      width: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }
  .btn {
    font-size: 14px;
    color: #999999;
  }
}
.def {
  text-align: center;
  margin-top: 30px;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 10px;
  .bg {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    .item {
      box-shadow: 0px 5px 20px 5px rgba(1, 15, 45, 0.08);
      padding-bottom: 10px;
      .img {
        width: 100%;
        position: relative;
        height: 170px;
        img {
          width: 100%;
          vertical-align: middle;
          height: 100%;
        }
        .over {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: rgba(98, 98, 98, 0.8);
          img {
            height: auto;
            width: 70%;
            margin: 0 auto;
          }
        }
      }
      .title {
        font-size: 16px;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 10px;
      }
      .total {
        display: flex;
        margin: 5px 0;
        padding: 0 10px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .pic {
        display: flex;
        padding: 0 10px;
        align-items: flex-end;
        .left {
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ff9228;
          line-height: 19px;
          span {
            font-size: 12px;
          }
          margin-right: 5px;
        }
        .right {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 19px;
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>
